footer.footer {
  background-color: var(--emu-common-colors-black);
  position: relative;
  z-index: var(--emu-common-other-z-index-header);

  .content-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1568px; // 1440px + 2 * 64px (horizontal padding)
    padding-left: var(--emu-common-spacing-medium);
    padding-right: var(--emu-common-spacing-medium);
    padding-top: 60px;
    padding-bottom: 167px; // 8px subtracted as the last element adds that much amount of space

    @include mq('large') {
      padding-left: var(--emu-common-spacing-xl);
      padding-right: var(--emu-common-spacing-xl);
      padding-top: var(--emu-common-spacing-xl);
      padding-bottom: var(--emu-common-spacing-xl);
    }
  }

  .footer-links {
    margin-bottom: 50px;

    @include mq('large') {
      display: flex;
      gap: var(--emu-common-spacing-large);
      margin-bottom: var(--emu-common-spacing-none);
      margin-left: 13px;
      margin-right: 36px;
    }

    ul {
      padding: var(--emu-common-spacing-none);
      margin: var(--emu-common-spacing-none);
      list-style: none;
    }

    li {
      margin-bottom: 34px;
      text-align: center;

      @include mq('large') {
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: var(--emu-common-spacing-large);
      }

      a {
        color: var(--emu-common-colors-white);
        text-decoration: underline;
        text-underline-offset: 5px;
        font-size: var(--emu-semantic-font-sizes-narrow-medium);
        line-height: normal;
        font-family: var(--emu-common-font-families-sans);

        @include mq('large') {
          font-weight: var(--emu-common-font-weight-bold);
          text-underline-offset: 3px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .links-container--first-no-underline {
      > ul > li:first-child {
        a {
          text-decoration: none;
        }
      }
    }
  }

  .footer-top-container {
    border-bottom: var(--emu-common-border-width-medium) solid
      var(--emu-common-colors-white);
    padding-bottom: var(--emu-common-spacing-xl);

    @include mq('large') {
      display: flex;
    }

    .links-and-image {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: end;
      column-gap: var(--emu-common-spacing-xs);
      margin-bottom: var(--emu-common-spacing-large);

      li:last-of-type {
        margin-bottom: var(--emu-common-spacing-none);
      }

      a {
        white-space: nowrap; // to create an illusion of image being next to the text
      }

      img {
        width: 30px;
        position: relative;
        left: var(--emu-common-spacing-xs);
      }
    }

    > .image {
      align-self: center;
    }

    .natrelle-logo {
      margin-bottom: var(--emu-common-spacing-large);

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
        margin-right: 20px;
      }

      img {
        margin: auto;
        width: 284px; // from the design
      }
    }

    .footer-social-media {
      display: flex;
      justify-content: center;
      column-gap: var(--emu-common-spacing-medium);

      @include mq('large') {
        column-gap: 7.15px; // from the design
      }

      img {
        flex-shrink: 0;
        width: 47px; // from the design

        @include mq('large') {
          width: 21px;
        }
      }
    }
  }

  .footer-bottom-container {
    padding-top: var(--emu-common-spacing-xl);

    @include mq('large') {
      display: flex;
      column-gap: 180px;
    }

    &__logo {
      width: 270px; // from the design
    }

    .aaaem-text {
      margin-top: 52px;

      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
      }

      p {
        color: var(--emu-common-colors-white);
        margin-bottom: var(--emu-common-spacing-small);
        font-size: var(--emu-semantic-font-sizes-wide-small);
        line-height: normal;
        font-family: var(--emu-common-font-families-sans);

        @include mq('large') {
          font-size: 20px;
        }
      }

      a {
        &,
        &:active,
        &:focus,
        &:hover {
          color: var(--emu-common-colors-white);
          text-decoration: none;
        }
      }
    }
  }
}

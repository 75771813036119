.video {
  &__title {
    padding-top: 52px;

    @include mq('large') {
      padding-top: 68px;
      max-width: 664px; //as per design
    }

    .cmp-title__text {
      padding-left: 21px;
      color: var(--emu-semantic-colors-secondary-200);
      font-weight: var(--emu-common-font-weight-regular);
      padding-right: 21px;
      margin-bottom: 15px;
      letter-spacing: -0.73px;

      @include mq('large') {
        margin-bottom: 7px;
        padding-left: var(--emu-semantic-spacing-none);
        padding-right: var(--emu-semantic-spacing-none);
        letter-spacing: -1.06px;
      }
    }
  }

  &__element {
    margin-left: -16px;
    margin-right: -16px;

    @include mq('large') {
      max-width: 1022px; //as per design
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.patientfi-isi {
  &-container {
    &.aaaem-isi-container.isi-container {
      z-index: var(--emu-common-other-z-index-isi);
    }

    // utilities to increase specificity
    &.patientfi-isi {
      &__mb-none {
        margin-bottom: var(--emu-common-spacing-none);
      }

      &__bg-white-alpha {
        background-color: #ffffffe6;
      }
    }

    &__title {
      h3 {
        font-family: var(--emu-semantic-font-families-gotham-bold);
        font-size: 16px;
        font-weight: var(--emu-common-font-weight-bold);
        line-height: var(--emu-common-line-heights-wide-medium);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: 14px;

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
        }
      }

      sup {
        vertical-align: super;
        font-size: 65%;
      }
    }

    &__description {
      font-size: 16px;
      margin-bottom: 24px;
      color: var(--emu-semantic-colors-tertiary-200);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
      }

      p,
      b {
        line-height: var(--emu-common-line-heights-wide-medium);
      }

      p {
        margin-bottom: 14px;
      }

      b {
        font-family: var(--emu-semantic-font-families-gotham-medium);
        font-weight: var(--emu-common-font-weight-regular);

        @include mq('large') {
          font-family: var(--emu-semantic-font-families-gotham-bold);
          font-weight: var(--emu-common-font-weight-bold);
        }
      }

      sup {
        font-size: 100%;
      }

      // utilities to increase specificity
      &.patientfi-isi {
        &__margin-sm {
          p {
            margin-bottom: 14px;
          }
        }

        &__margin-md {
          p {
            margin-bottom: 24px;
          }
        }

        &__margin-none {
          margin-bottom: var(--emu-common-spacing-none);

          p {
            &:last-child {
              margin-bottom: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    &__question {
      h3 {
        font-family: var(--emu-semantic-font-families-gotham-bold);
        font-weight: var(--emu-common-font-weight-bold);
        font-size: 16px;
        line-height: var(--emu-common-line-heights-wide-large);

        @include mq('large') {
          font-size: var(--emu-common-font-sizes-wide-medium);
        }
      }
    }

    &__answer {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: var(--emu-common-line-heights-wide-medium);
      color: var(--emu-semantic-colors-tertiary-200);

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-narrow-small);
      }

      b {
        font-family: var(--emu-semantic-font-families-gotham-medium);
        font-weight: var(--emu-common-font-weight-regular);
      }

      ul {
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        padding-left: 25px;
        line-height: var(--emu-common-line-heights-wide-large);
      }

      a {
        color: var(--emu-semantic-colors-secondary-100);
      }
    }

    .aaaem-isi-banner {
      background-color: var(--emu-semantic-colors-secondary-100);
      position: relative;
      isolation: isolate;
      min-height: 40px;

      .aaaem-isi-container__button-wrapper,
      .aaaem-isi-container__banner-content {
        // from the ref website
        width: 95%;
        // Actual: 1270px
        // Its better to match the
        // ISI content container's `max-width`
        max-width: 1268px;
        margin: auto;
      }

      .aaaem-isi-container__banner-content {
        position: absolute;
        left: 0;
        right: 0;
        // the content should be present always
        // even when the ISI is expanded
        display: inline-block !important;

        p {
          font-size: 11px;
          line-height: 16px;
          color: var(--emu-common-colors-white);
          font-family: var(--emu-semantic-font-families-gotham-bold);
          font-weight: var(--emu-common-font-weight-bold);
          margin-top: var(--emu-common-spacing-none);
          margin-bottom: var(--emu-common-spacing-none);
          padding-right: 75px;

          @include mq('large') {
            font-size: 14px;
            line-height: 19.6px;
          }
        }
      }

      .aaaem-isi-container__button-wrapper {
        position: relative;
        z-index: var(--emu-common-other-z-index-base);

        button {
          margin-left: auto;
          padding: var(--emu-common-spacing-none);
          color: var(--emu-semantic-colors-secondary-200);
          font-family: var(--emu-semantic-font-families-gotham-bold);
          font-weight: var(--emu-common-font-weight-bold);
          font-size: 10px;
          line-height: 14px;

          @include mq('large') {
            font-size: 12px;
            line-height: 16.8px;
          }
        }
      }
    }

    .aaaem-isi-container__isi-content {
      > div > .container > .aaaem-container {
        // from the design
        max-width: 1268px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    // styles related to the isi content when its expanded
    .aaaem-isi-container__isi-content.js-expand {
      overflow: hidden;

      &,
      // This is the only way to target the correct element
      >div>.container>.aaaem-container {
        background-color: var(--emu-common-colors-transparent);
      }

      > div,
      > div > .container {
        height: 100%;
      }

      > div > .container > .aaaem-container {
        padding-left: var(--emu-common-spacing-none);
        padding-top: 28px;
        padding-right: var(--emu-common-spacing-none);
        // from the design (visually matched)
        padding-bottom: var(--emu-common-spacing-medium);
        // from the ref website
        width: 95%;
        height: 100%;
        overflow-y: auto;

        @include mq('large') {
          padding-bottom: var(--emu-common-spacing-xl);
        }
      }
    }
  }
}

.emu-consent-banner {
  &.js-visible {
    bottom: var(--isiElHeight);
    display: block;
    transition: height .5s ease-out 0s, bottom 0s ease-in-out 0s;
  }
}

.footer-in-viewport {
  .patientfi-isi-container {
    .aaaem-isi-banner {
      display: none;
    }
  }
}

.intro {
  &__title--style.cmp-title {
    .emu-title__text.cmp-title__text {
      color: var(--emu-semantic-colors-secondary-400);
    }

    @include mq('large') {
      max-width: 738px; //as per design
    }
  }

  .button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: var(--emu-common-spacing-large);

    @include mq('large') {
      margin-top: 41px;
    }
  }
}

[data-id="redirect-modal"] {
    .modal-wrapper {
        overflow: hidden;
    }
    .main-close {
        display: none;
    }
    .modal-content {
        margin-left: 16px;
        margin-right: 16px;
        padding-bottom: 64px;
        padding-left: 32px;
        padding-right: 32px;
        padding-top: 64px;
        width: calc(100% - 32px);
        @include mq('medium') {
            margin-left: auto;
            margin-right: auto;
            width: var(--emu-component-layers-modal-body-sizing-width);
        }
        @include mq('large') {
            left: 50%; 
            margin: 0;
            padding-left: 64px;
            padding-right: 64px;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
        }
    }
    h2,
    [data-component="text"] p {
        color: var(--emu-semantic-colors-secondary-200);
        font-family: var(--emu-semantic-font-families-body);
    }
    h2 {
        font-size: 32px;
        line-height: 35px;
        @include mq('large') {
            font-size: 40px;
            line-height: 44px;
        }
    }
    [data-component="text"] p {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 32px;
        margin-top: 32px;
    }
    .modal-cta-container {
        gap: 16px;
    }
    .aaaem-button {
        .cmp-button__text {
            font-family: var(--emu-semantic-font-families-body);
        }
        &.aaaem-button__primary-outline {
            background-color: transparent;
            color: var(--emu-semantic-colors-secondary-200);
            border-color: var(--emu-semantic-colors-secondary-200);
            border-width: 2px;
            &:hover,
            &:active,
            &:focus {
                background-color: transparent;
                color: var(--emu-semantic-colors-secondary-200);
                border-color: var(--emu-semantic-colors-secondary-200);
            }
            &:hover {
                background-color: rgba(0, 48, 92, 0.1);
            }
            &:active,
            &:focus {
                background-color: rgba(0, 48, 92, 0.3);
            }
        }
        &.aaaem-button__primary-filled {
            background-color: var(--emu-semantic-colors-secondary-200);
            border-color: var(--emu-semantic-colors-secondary-200);
            border-width: 2px;
            color: var(--emu-common-colors-white);
            &:hover,
            &:active,
            &:focus {
                border-color: var(--emu-semantic-colors-secondary-200);
                color: var(--emu-common-colors-white);
            }
            &:hover {
                background-color: rgba(0, 48, 92, 0.5);
            }
            &:active,
            &:focus {
                background-color: rgba(0, 48, 92, 0.8);
            }
        }
    }
}

.js-modal-opened {
    height: 100%;
    overflow-y: hidden;
}
[data-component='text'],
[data-component='title'] {
  p,
  li {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
    margin: var(--emu-common-spacing-none);
  }
}

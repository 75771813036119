.custom-tab {
  &__container {
    position: relative;
    // from the design (visually matched)
    margin-top: -88px;

    @include mq('large') {
      // from the design (visually matched)
      margin-top: -376px;
    }

    .aaaem-tabs {
      &__tablist {
        display: none;
      }

      &__tabpanel {
        // panel should be above the tab button
        position: relative;
        margin-top: var(--emu-common-spacing-xs);

        @include mq('large') {
          margin-top: var(--emu-common-spacing-small);
        }

        // Since this is the container that
        // has `background-color` applied via token
        > .container > .aaaem-container {
          border-top-left-radius: var(--emu-common-border-radius-xs);
          border-top-right-radius: var(--emu-common-border-radius-xs);
          padding-left: var(--emu-common-spacing-medium);
          padding-top: 40px;
          padding-right: var(--emu-common-spacing-medium);

          @include mq('large') {
            padding-left: 104px;
            padding-top: 56px;
            padding-right: 104px;
          }
        }
      }
    }
  }

  &__btns-wrapper {
    display: flex;
    padding-left: var(--emu-common-spacing-small);
    padding-top: var(--emu-common-spacing-small);
    padding-right: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
    border-radius: var(--emu-common-border-radius-xs);
    // The three property-value pairs below
    // are from the design file
    background: linear-gradient(158deg, #ffffff24 7.32%, #ffffff82 91.59%);
    box-shadow: 0px 4px 24px -1px #00000033;
    backdrop-filter: blur(20px);

    @include mq('large') {
      padding-left: 43px;
      padding-top: 22px;
      padding-right: 43px;
      padding-bottom: 22px;
    }

    .button {
      flex-grow: 1;
      flex-basis: 0;
      max-height: 80px; //as per design
      display: flex;

      > button {
        margin-right: var(--emu-common-spacing-small);

        @include mq('large') {
          margin-right: 24px;
        }

        &.mr-0 {
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    // placement of this is intentional to increase the specificity
    .custom-tab__btn {
      // these classes are needed to increase the specificity
      &.emu-button.emu-button-v2 {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--emu-common-colors-white);
        border-width: var(--emu-common-border-width-thin);
        border-style: solid;
        border-color: currentColor;
        border-radius: var(--emu-common-border-radius-xxs);
        padding-left: var(--emu-common-spacing-small);
        padding-top: 11px;
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: 11px;

        @include mq('large') {
          border-radius: var(--emu-common-border-radius-xs);
          padding-top: 19px;
          padding-bottom: 19px;
        }

        &,
        &:hover,
        &:active {
          background-color: var(--emu-common-colors-transparent);
        }

        > img {
          width: auto;
          // Ideal to set image's height
          // as intrinsic image height would break the design
          height: 20px;
          // from the design (important)
          max-width: 105px;
          object-fit: contain;
          filter: brightness(0) invert(1);

          @include mq('large') {
            height: 40px;
            max-width: 210px;
          }
        }

        &.custom-tab__btn--active {
          background-color: currentColor;

          &.custom-tab__btn--secondary-100 {
            color: var(--emu-semantic-colors-secondary-100);
          }

          &:not(.custom-tab__btn--secondary-100) {
            color: var(--emu-semantic-colors-primary-dark);

            > img {
              filter: brightness(1) invert(0);
            }
          }
        }
      }
      &--sm-img {
        // these classes are needed to increase the specificity
        &.emu-button.emu-button-v2 {
          padding-top: 13px;
          padding-bottom: 13px;

          @include mq('large') {
            padding-top: 21.6px;
            padding-bottom: 21.6px;
          }

          > img {
            max-height: 18px; //from the design
            width: 78.26px; //from the design

            @include mq('large') {
              max-height: 36.8px; //from the design
              width: 160px; //from the design
            }
          }
        }
      }
    }
  }
}

#patientfi {
  .why-choose-us {
    // to void `z-index` leak
    isolation: isolate;
    // to position the image
    position: relative;
    display: flex;
    flex-direction: column;

    @include mq('x-large') {
      flex-direction: row;
      // to make sure it contains the
      // absolute positioned default image
      min-height: 360px;
    }

    // no other way to select the direct children
    > .container {
      @include mq('x-large') {
        flex-basis: 0;
        flex-grow: 1;
      }
    }

    &__container {
      margin-top: 56px;
      margin-bottom: 66px;
      display: flex;
      flex-direction: column;

      @include mq('x-large') {
        margin-top: 66.84px;
        margin-bottom: 25px;
      }
    }

    &__img {
      // to place it above the horizontal dashed lines
      position: relative;
      z-index: var(--emu-common-other-z-index-layer);
      // actual value: 37px
      // add the remaining here
      padding-bottom: 12px;
      margin-bottom: 25px;

      @include mq('x-large') {
        position: absolute;
        left: 50%;
        top: 44%;
        transform: translate(-50%, -50%);
        padding-bottom: var(--emu-common-spacing-none);
      }

      img {
        margin-left: auto;
        margin-right: auto;
        // from the design
        width: 308.584px;

        @include mq('x-large') {
          margin-bottom: var(--emu-common-spacing-none);
          // from the design
          width: 359.158px;
        }
      }
    }

    &__benefit {
      &-title {
        h4 {
          // better to do it here than authoring
          // as the alignment changes with screen width change
          text-align: center;
          margin-bottom: var(--emu-common-spacing-xs);
          font-size: var(--emu-semantic-font-sizes-narrow-xl);
          line-height: var(--emu-semantic-line-heights-narrow-xl);
          color: var(--emu-semantic-colors-secondary-400);
          letter-spacing: -0.96px;

          @include mq('x-large') {
            margin-bottom: 7px;
          }

          p {
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
            color: inherit;
            letter-spacing: inherit;
            display: inline;

            @include mq('x-large') {
              display: block;
            }

            sup {
              vertical-align: super;
              // fuzzy value (matched visually)
              font-size: 60%;
            }
          }
        }

        &--mobile-px {
          &-sm {
            h4 {
              @include mq(1023px, 'max-width') {
                padding-left: var(--emu-common-spacing-medium);
                padding-right: var(--emu-common-spacing-medium);
              }
            }
          }

          &-lg {
            h4 {
              @include mq(1023px, 'max-width') {
                padding-left: 78px;
                padding-right: 78px;
              }
            }
          }
        }

        // the `p` tag with the dot and dashed horizontal line
        p:last-of-type {
          & {
            // to make sure the dot and dashed line
            // start the ending of text
            width: fit-content;
            // to act as an anchor
            // for its ::before & ::after elements
            position: relative;
          }

          // the dot
          &::before {
            @include mq('x-large') {
              content: '';
              display: inline-block;
              background-color: var(--emu-semantic-colors-secondary-100);
              width: var(--emu-common-sizing-xs);
              height: var(--emu-common-sizing-xs);
              border-radius: 100%;
              position: absolute;
              // fuzzy value (matched visually)
              bottom: 6px;
            }
          }

          // the dashed horizontal line
          &::after {
            @include mq('x-large') {
              content: '';
              display: inline-block;
              border-width: var(--emu-common-border-width-thin);
              border-color: var(--emu-semantic-colors-secondary-100);
              border-style: dashed;
              position: absolute;
              bottom: 9px;
              // guarantees the line will reach
              // the centered image
              width: 100vh;
            }
          }
        }

        &--first-line-blank {
          h4 {
            p:first-child {
              display: none;
            }
          }
        }
      }

      &-description {
        p {
          font-size: 14px;
          margin-bottom: 25px;
          letter-spacing: -0.56px;
          color: var(--emu-semantic-colors-tertiary-dark);
          text-align: center;

          @include mq('x-large') {
            // from the design
            max-width: 295px;
            font-size: var(--emu-common-font-sizes-wide-medium);
            line-height: var(--emu-semantic-line-heights-narrow-small);
          }
        }

        &--mobile-px {
          p {
            @include mq(1023px, 'max-width') {
              padding-left: 54px;
              padding-right: 54px;
            }
          }
        }

        &--desktop {
          &-max-w {
            &-sm {
              p {
                @include mq('x-large') {
                  max-width: 250px;
                }
              }
            }

            &-lg {
              p {
                @include mq('x-large') {
                  max-width: 270px;
                }
              }
            }
          }
        }
      }
    }

    &__benefits {
      display: flex;
      flex-direction: column;

      @include mq('x-large') {
        // to hide the dashed horizontal lines
        overflow: hidden;
      }

      &--desktop {
        &-left {
          p {
            @include mq('x-large') {
              text-align: left;
            }
          }

          h4 {
            p:last-of-type {
              // 28px or -28px represents
              // the space between the last character
              // of the description and the blue dot
              &::before {
                @include mq('x-large') {
                  right: -28px;
                }
              }

              &::after {
                @include mq('x-large') {
                  left: calc(100% + 28px);
                }
              }
            }
          }
        }

        &-right {
          p {
            @include mq('x-large') {
              margin-left: auto;
              text-align: right;
            }
          }

          h4 {
            p:last-of-type {
              // 28px or -28px represents
              // the space between the last character
              // of the description and the blue dot
              &::before {
                @include mq('x-large') {
                  left: -28px;
                }
              }

              &::after {
                @include mq('x-large') {
                  right: calc(100% + 28px);
                }
              }
            }
          }
        }

        &-mv-top-sm {
          @include mq('x-large') {
            // fuzzy value (matched visually)
            transform: translateY(-13.2px);
          }
        }
      }
    }

    &__content-info {
      margin-left: auto;
      margin-right: auto;
      margin-top: 3px;

      @include mq('x-large') {
        position: relative;
        margin-top: -8px;
        margin-right: var(--emu-common-spacing-none);
      }

      p {
        font-size: 10px;
        
        color: var(--emu-semantic-colors-tertiary-dark);
        letter-spacing: -0.4px;
        text-align: center;

        @include mq('x-large') {
          text-align: right;
        }
      }
    }
  }
}

// author specific styles
.why-choose-us {
  @include aem-editor-view {
    display: block !important;
  }
}

.why-choose-us__container {
  @include aem-editor-view {
    position: static !important;
  }
}

.why-choose-us__img {
  @include aem-editor-view {
    position: static !important;
    transform: none !important;
  }
}

.for_patients_dropdown {
    &.emu-card {
        margin: 0;
        .emu-card {
            &__wrapper {
                background-color: transparent;
                border-radius: 0;
                padding: 0;
            }
            &__title-wrapper {
                background-color: transparent;
                border: none;
                border-radius: 0;
                padding-bottom: 9px;
                padding-left: 15px;
                padding-right: 15px;
                padding-top: 9px;
                &:hover,
                &:focus,
                &:active {
                    background-color: transparent;
                }
                h5 {
                    align-items: center;
                    color: var(--emu-semantic-colors-tertiary-300);
                    display: flex;
                    font-family: var(--emu-semantic-font-families-gotham-medium);
                    font-weight: var(--emu-semantic-font-weight-500);
                    font-size: 9.5px;
                    line-height: 12px;
                    margin: 0;
                    &::before {
                        content: '';
                        background-image: url("../../assets/images/country-flags/us_flag.png");
                        display: inline-block;
                        height: 24px;
                        margin-right: 8px;
                        width: 24px;
                    }
                }
            }
            &__content-wrapper {
                color: var(--emu-semantic-colors-tertiary-300);
                background-color: #dddddd;
                position: absolute;
                width: 100%;
                z-index: var(--emu-common-other-z-index-modal);
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding-left: 0;
                }
                a {
                    color: inherit;
                    display: inline-block;
                    font-family: 'GothamBold';
                    font-size: 9.5px;
                    line-height: 12px;
                    padding: 10px 20px;
                    text-decoration: none;
                    text-wrap: nowrap;
                    width: 100%;
                    &:hover {
                        background-color: #b5b5b5; 
                    }
                }
            }
            &__toggle-cta {
                margin-left: 8px;
                .emu-button {
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    .chevron {
                        height: 12px;
                        width: 12px;
                        svg path {
                            stroke: var(--emu-semantic-colors-tertiary-300);
                            stroke-width: 2px;
                        }
                    }
                }
                
            }
            &__body {
                .aaaem-text {
                    margin: 0;
                }
                .aaaem-container {
                    width: 100%;
                }
                .countries-dropdown-menu {
                    .countries {
                        align-items: center;
                        display: flex;
                        &::before {
                            content: "";
                            width: 24px;
                            height: 24px;
                            margin-right: 8px;
                            display: inline-block;
                        }
                    }
                    .canada {
                        &::before {
                            background-image: url("../../assets/images/country-flags/canada_flag.png");
                        }
                    }
                }
            }
        }
        footer {
            margin: 0;
            padding: 0;
        }
        .aaaem-button.caret-icon {
            .chevron{
                svg {
                    transform: rotate(0deg) scale(0.75);
                }
            }
            &.js-toggle-on {
                .chevron{
                    svg {
                        transform: rotate(180deg) scale(0.75);
                    }
                }
            }
        }
    }
}
#patientfi {
  .patientfi {
    &__find-surgeon {
      background-color: var(--emu-common-colors-transparent);
      border-width: var(--emu-common-border-width-thin);
      border-style: solid;
      border-color: var(--emu-common-colors-white);
      border-top: none;
      padding-left: var(--emu-common-spacing-small);
      padding-top: 36px;
      padding-right: var(--emu-common-spacing-small);
      padding-bottom: 24px;

      @include mq('large') {
        border-width: var(--emu-common-border-width-medium);
        padding-top: 48px;
        padding-bottom: 36px;
      }

      .form-has-error {
        button[type='submit'] {
          opacity: 0.4;
          pointer-events: none;
        }
      }

      &-container {
        // Cannot use a utility to set the bg color
        // because of its low specificity
        background-color: var(--emu-semantic-colors-primary-50);
        padding-left: var(--emu-common-spacing-small);
        padding-right: var(--emu-common-spacing-small);
        padding-bottom: var(--emu-common-spacing-medium);
        margin-bottom: 52px;

        @include mq('large') {
          padding-left: 12px;
          padding-right: 12px;
          padding-bottom: 12px;
          margin-bottom: 73px;
        }
      }

      &-title {
        margin-bottom: 36px;

        h3 {
          // actual value: 350
          font-weight: var(--emu-common-font-weight-regular);
          color: var(--emu-semantic-colors-secondary-200);
          font-size: var(--emu-semantic-font-sizes-narrow-xxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          letter-spacing: -0.59px; //To match the design 

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xl);
            line-height: var(--emu-semantic-line-heights-wide-xl);
          }
        }
      }

      &-form {
        // 537px +2px +16px = 555px
        // 537px (from the design)
        // 2px   (1px border width on both sides)
        // 16px  (8px padding on both sides)
        width: min(100%, 555px);
        position: relative;
        margin-left: auto;
        margin-right: auto;
        border-width: var(--emu-common-border-width-thin);
        border-style: solid;
        border-color: var(--emu-common-colors-white);
        padding: var(--emu-common-spacing-xs);

        @include mq('large') {
          padding: var(--emu-common-spacing-small);
        }

        .button {
          position: static;
        }

        button[type='submit'] {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          // 4px + 8px = 12px
          // 4px (padding of relative positioned ancestor)
          // 8px (actual padding)
          right: 12px;
          font-size: var(--emu-common-sizing-none);
          // from the design (necessary)
          width: 40px;
          // from the design (necessary)
          height: 40px;
          background-color: var(--emu-semantic-colors-secondary-100);
          background-image: url('../../assets/images/search.png');
          background-repeat: no-repeat;
          background-position: center;
          @include mq('large') {
            // padding of relative positioned ancestor changed
            // from 4px to 8px
            right: 16px;
          }
          &:hover {
            filter: brightness(110%);
          }
          &:active {
            filter: brightness(85%);
          }
        }

        .error-message {
          font-family: var(--emu-common-font-families-sans);
          font-size: var(--emu-common-font-sizes-narrow-medium);
          line-height: var(--emu-common-line-heights-narrow-medium);
          color: var(--emu-common-colors-red-600);
          margin-top: var(--emu-common-spacing-xs);
          margin-bottom: var(--emu-common-spacing-none);
          position: absolute;
          bottom: -22px;
          left: 4px;

          @include mq('large') {
            left: 8px;
          }
        }
      }

      &-text-input {
        input {
          width: 100%;
          border-width: var(--emu-common-border-width-none);
          font-size: 14px;
          line-height: 18.2px;
          padding-left: 24px;
          // 8px +20px -9.1px = 18.9px
          // 8px    (actual padding)
          // 20px   (40px / 2, half of the largest element)
          // -9.1px (18.2px / 2, half of the line height)
          padding-top: 18px;
          // 8px +40px +8px = 56px
          // 8px  (actual padding)
          // 40px (width of the search button)
          // 8px  (additional space to make it look good)
          padding-right: 56px;
          padding-bottom: 18px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
            line-height: 23.4px;
            // line-height changed
            // from 18.2px to 23.4px
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }

      &-redirect-url {
        display: none;
      }
    }
  }
}

// author styles to make sure that the redirect URL is visible and authorable
.patientfi__find-surgeon-redirect-url {
  @include aem-editor-view {
    display: block !important;
  }
}

#patientfi {
  .get-started-today {
    // to visually contain
    // the translated achievements
    padding-top: 78px;
    padding-bottom: 30px;

    @include mq('large') {
      padding-top: 95px;
      padding-bottom: 26px;
    }

    &__main-content-container {
      background-color: var(--emu-semantic-colors-tertiary-50);
      padding-left: var(--emu-common-spacing-small);
      padding-top: 11px;
      padding-right: var(--emu-common-spacing-small);
      margin-bottom: 21px;

      @include mq('large') {
        padding-left: 12px;
        padding-top: 13px;
        padding-right: 12px;
        margin-bottom: 27px;
      }
    }

    &__main-content {
      border-width: var(--emu-common-border-width-thin);
      border-style: solid;
      border-color: var(--emu-common-colors-white);
      border-bottom: none;
      background-color: var(--emu-common-colors-transparent);
      padding-bottom: 54px;

      @include mq('large') {
        padding-bottom: 44px;
        border-width: var(--emu-common-border-width-medium);
      }
    }

    &__achievements {
      background-color: var(--emu-common-colors-transparent);
      display: flex;
      flex-direction: column;
      align-items: center;
      // -78px -1px -11px = -90px
      // -78px (from the design)
      // -1px  (`border-width` of `.get-started-today__main-content`)
      // -11px (`padding-top` of `.get-started-today__main-content-container`)
      transform: translateY(-90px);
      // 34.56px -90px = -55.44px
      // 34.56px (from the design - the space between the achievements and title)
      // -90px   (the vertical translation above)
      margin-bottom: -55.44px;

      @include mq('large') {
        flex-direction: row;
        justify-content: center;
        // -95px -2px -13px = -110px
        transform: translateY(-110px);
        // 48px -110px = -62px
        margin-bottom: -62px;
      }
    }

    &__achievement {
      // to avoid `z-index` leak
      isolation: isolate;
      // to contain and constraint
      // its `::after` element
      position: relative;
      background-color: var(--emu-semantic-colors-primary-50);
      // from the design (necessary)
      width: 160px;
      // from the design (necessary)
      height: 160px;
      border-radius: 100%;
      // 36.15px +1.93px = 38.08px
      // 36.15px (from the design)
      // 1.93px  (the `border-width` of `::after` element)
      padding-top: 38.08px;
      border-width: 1.15px;
      border-style: dashed;
      border-color: var(--emu-semantic-colors-secondary-100);
      margin-bottom: 14.22px;

      @include mq('large') {
        padding-top: 49.5px;
        border-width: 1.5px;
        width: 208px;
        height: 208px;
        margin-right: 72px;
        margin-bottom: var(--emu-common-spacing-none);
      }

      &.mobile-mb-0 {
        @include mq(1023px, 'max-width') {
          margin-bottom: var(--emu-common-spacing-none);
        }
      }

      &.desktop-mr-0 {
        @include mq('large') {
          margin-right: var(--emu-common-spacing-none);
        }
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border-width: 1.93px;
        border-style: solid;
        border-color: var(--emu-common-colors-white);

        @include mq('large') {
          border-width: 2.5px;
        }
      }

      // the score text
      h3 {
        color: var(--emu-semantic-colors-secondary-400);
        // to place the text above the
        // `::after` element of its parent
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
        font-family: var(--emu-semantic-font-families-heading);
        font-size: 40px;
        line-height: 44px;
        letter-spacing: -1.6px;
        margin-bottom: 2.77px;

        @include mq('large') {
          font-size: var(--emu-semantic-font-sizes-wide-xxxl);
          line-height: var(--emu-semantic-line-heights-wide-xxxl);
          letter-spacing: -2.08px;
          margin-bottom: var(--emu-common-spacing-xs);
        }
      }

      p {
        color: var(--emu-semantic-colors-secondary-400);
        // to place the text above the
        // `::after` element of its parent
        position: relative;
        z-index: var(--emu-common-other-z-index-layer);
        font-size: 14px;
        letter-spacing: -0.56px;
        padding-left: 20px;
        padding-right: 20px;

        @include mq('large') {
          line-height: 22.4px;
          font-size: var(--emu-common-font-sizes-wide-medium);
          letter-spacing: normal;
          padding-left: 30px;
          padding-right: 30px;
        }
      }
    }

    &__title {
      margin-bottom: var(--emu-common-spacing-small);
      color: var(--emu-semantic-colors-secondary-400);
      padding-left: 24px;
      padding-right: 24px;
      letter-spacing: -1.12px;

      @include mq('large') {
        letter-spacing: -1.6px;
      }
    }

    &__description {
      color: var(--emu-semantic-colors-tertiary-dark);
      line-height: var(--emu-semantic-line-heights-narrow-small);
      margin-bottom: 47px;

      @include mq('large') {
        line-height: var(--emu-semantic-line-heights-wide-small);
        margin-bottom: 27px;
      }
    }

    &__btn {
      letter-spacing: -0.8px;
      padding-left: 38px;
      padding-right: 38px;

      @include mq('large') {
        letter-spacing: -0.96px;
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    &__info {
      &--disclaimer {
        margin-bottom: 31px;

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-large);
        }

        p {
          &:not(:last-child) {
            margin-bottom: 17px;

            @include mq('large') {
              margin-bottom: 11px;
            }
          }
        }
      }

      p {
        color: var(--emu-semantic-colors-tertiary-dark);
        font-size: 10px;
        line-height: 13px;
        letter-spacing: -0.5px;

        &:not(:last-child) {
          margin-bottom: 14px;

          @include mq('large') {
            margin-bottom: 26px;
          }
        }

        sup {
          vertical-align: super;
        }
      }
    }

    // to center the button
    .button {
      display: flex;

      .get-started-today__btn {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

// author specific styles
.get-started-today__achievements {
  @include aem-editor-view {
    margin-bottom: var(--emu-common-spacing-none) !important;
    transform: none !important;
  }
}

#error-page {
  .intro__title {
    max-width: 800px;
  }
  h2, 
  h2 p {
    color: var(--emu-common-colors-teal-550);
    font-family: var(--emu-semantic-font-families-heading);
    font-size: 96px;
    line-height:  89px;
    @include mq('large') {
      display: block;
      font-size: 124px;
      line-height: 111px;
      margin: auto;
    }
  }
  .error__description {
    p {
      color: var(--emu-semantic-colors-secondary-200);
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 32px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 32px;
      @include mq('large') {
        margin-bottom: 64px;
        margin-top: 64px;
        max-width: 580px;
      }
    }
  }
  .emu-button {
    span,
    .cmp-button__text {
        font-family: var(--emu-semantic-font-families-body);
        font-size: 12px;
        line-height: 13px;
    }
    &.emu-button__primary-filled {
        background-color: var(--emu-semantic-colors-secondary-200);
        border-color: var(--emu-semantic-colors-secondary-200);
        border-width: 2px;
        color: var(--emu-common-colors-white);
        padding: 16px 32px;
        &:hover,
        &:active,
        &:focus {
            border-color: var(--emu-semantic-colors-secondary-200);
            color: var(--emu-common-colors-white);
        }
        &:hover {
            background-color: rgba(0, 48, 92, 0.5);
        }
        &:active,
        &:focus {
            background-color: rgba(0, 48, 92, 0.8);
        }
    }
  }
}

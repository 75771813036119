#patientfi {
  .patientfi {
    &__main-teaser {
      isolation: isolate;

      &-container {
        position: relative;
        height: 470px;

        @include mq('large') {
          height: 926px;
        }
      }

      .cmp-teaser__image {
        img {
          object-fit: cover;
          // from the design
          height: 470px;

          @include mq('large') {
            height: 926px;
          }
        }
      }

      &-title-img-container,
      .content-container {
        // from the design
        margin-left: auto;
        margin-right: auto;

        @include mq('medium') {
          max-width: 700px;
        }

        @include mq('large') {
          max-width: 900px;
        }

        @include mq('x-large') {
          max-width: 1246px; //from the design
        }
      }

      &-title-img-container {
        position: absolute;
        // !important is necessary for IOS v14
        // to avoid chrome reducing the properties below to `inset`
        left: 0 !important;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }

      &-title-img {
        position: absolute;
        top: 21px;
        left: 12px;

        @include mq('large') {
          top: 69px;
          left: -8px;
        }

        // the content will be centered after `large` breakpoint
        // can afford to displace the image to the left
        // to match the design at `1440px`
        @include mq('x-large') {
          left: -16px;
        }
      }

      .content-container {
        position: absolute;
        // !important is necessary for IOS v14
        // to avoid chrome reducing the properties below to `inset`
        left: 0 !important;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        // to place it above the title image
        // so that the text can be selected
        z-index: var(--emu-common-other-z-index-layer);

        .cmp-teaser__description {
          // from the design
          max-width: 172px;
          padding-left: 3px;

          @include mq('medium') {
            // Not present in the design
            // to make the content look good
            max-width: 300px;
          }

          @include mq('large') {
            // from the design
            max-width: 440px;
          }

          p {
            font-size: 14px;
            line-height: 16.8px;
            letter-spacing: -0.56px;
            text-shadow: 0px 4px 4px #0000001a;

            @include mq('large') {
              font-size: 22px;
              line-height: var(--emu-semantic-line-heights-narrow-xxl);
              letter-spacing: normal;
            }

            sup {
              font-size: 100%;
              line-height: 18.2px;
            }
          }
        }

        .disclaimer {
          margin-bottom: auto;
          padding-left: 3px;

          p,
          sup {
            margin-top: var(--emu-common-spacing-none);
            margin-bottom: var(--emu-common-spacing-none);
            font-family: var(--emu-semantic-font-families-heading);
            font-size: 13px;
            line-height: 14.036px;
            letter-spacing: -0.52px;
            font-weight: var(--emu-semantic-font-weight-260);

            @include mq('large') {
              font-size: var(--emu-semantic-font-sizes-wide-small);
              line-height: 19.494px;
              letter-spacing: -0.72px;
            }
          }

          p:last-of-type {
            font-family: var(--emu-semantic-font-families-gotham-medium);
            font-size: 9px;
            font-weight: normal;
            line-height: 10.026px;
            letter-spacing: -0.36px;

            @include mq('large') {
              font-size: 12px;
              line-height: 14.4px;
              letter-spacing: -0.48px;
            }
          }
        }
      }

      &--hide-title {
        .title-lockup {
          display: none;
        }
      }

      &--content-custom-my {
        .content {
          margin-bottom: 150px;
          margin-top: 125px;

          @include mq('large') {
            margin-bottom: 94px;
            margin-top: 247px;
          }
        }
      }

      // to match the design but is unusual
      // variant can be removed from authoring for usual cases
      &--bg-custom-object-fit-desktop {
        .cmp-teaser__image {
          img {
            @include mq('large') {
              object-position: calc(50% - 5px) calc(50% - 40px);
            }
          }
        }
      }
    }
  }
}

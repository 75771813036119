header.experiencefragment {
  position: sticky;
  top: 0;
  z-index: var(--emu-common-other-z-index-header);
  transform: translateY(0);
  transition: all var(--emu-common-other-time-duration-instant);

  @include mq('large') {
    position: relative;
    transform: translateY(0);
  }

  &.hide {
    transform: translateY(-100%);

    @include mq('large') {
      transform: translateY(0);
    }
  }
}

.header {
  width: 100%;

  &__top-navbar {

    // utilities to increase specificity
    &.header__x-centered {
      max-width: 1128px;
      margin-left: auto;
      margin-right: auto;
    }

    &-container {
      position: relative;

      @include mq('large') {
        display: flex;
      }
    }

    &-container-expand-brands {
      font-size: 8px;
      line-height: 12px;
      position: relative;
      z-index: var(--emu-common-other-z-index-overlay);

      @include mq('large') {
        font-size: 9.5px;
        margin-left: var(--emu-common-spacing-xl);
      }

      &.active {
        ul {
          max-height: 450px;
          overflow: hidden;
        }
      }

      &.show-overflow {
        ul {
          overflow: auto;
        }
      }

      p {
        a {
          font-family: var(--emu-semantic-font-families-gotham-bold);
          font-weight: var(--emu-common-font-weight-bold);
          letter-spacing: 0.0306; // from the design (visually matched)
          white-space: nowrap;
          color: inherit;
          text-decoration: none;
          padding-top: 15px;
          padding-right: 14px;
          padding-bottom: 15px;
          padding-left: 10px;
          display: inline-block;

          @include mq('large') {
            padding-left: 15px;
          }
        }
      }

      ul {
        min-width: 100%; // as per live
        background-color: #ddd; // used only in this file
        list-style: none;
        padding-left: var(--emu-common-spacing-none);
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        overflow: hidden;
        position: absolute;
        top: 0;
        max-height: 0;
        transition: max-height var(--emu-common-other-time-duration-instant);

        @include mq('large') {
          top: 100%;
        }

        li {
          white-space: nowrap;

          &:hover {
            background-color: #adadad; // used only in this file
          }

          a {
            display: block;
            padding-top: 9.5px;
            padding-right: 20px;
            padding-bottom: 10.5px;
            padding-left: 10px;
            color: var(--emu-semantic-colors-tertiary-300);
            font-family: var(--emu-semantic-font-families-gotham-medium);
            font-weight: var(--emu-semantic-font-weight-500);
            font-size: 9.5px;
            text-decoration: none;

            @include mq('large') {
              padding-left: 20px;
            }
          }
        }
      }
    }

    &-container-menu {
      display: none;

      @include mq('large') {
        display: block;
      }

      ul {
        display: flex;
        list-style: none;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);

        li {
          font-family: var(--emu-semantic-font-families-gotham-medium);
          font-weight: var(--emu-semantic-font-weight-500);
          font-size: 9.5px;
          line-height: 12px;

          a {
            color: var(--emu-semantic-colors-tertiary-300);
            letter-spacing: 0.008px; // from the design (visually matched)
            word-spacing: 0.195px; // from the design (visually matched)
            white-space: nowrap;
            text-decoration: none;
            display: block;
            padding: 15px;
          }
        }
      }
    }

    &-container-countries-dropdown {
      display: inline-flex;
      align-items: center;
      padding-left: 10px;
      font-size: 8px;
      line-height: 12px;
      position: relative;

      @include mq('large') {
        font-size: 9.5px;
        padding-left: var(--emu-common-spacing-none);
        height: 100%;
      }

      // utilities to increase specificity
      &.header__margin-left-md {
        @include mq('large') {
          margin-left: 84px;
        }
      }

      &-trigger-container {
        display: flex;
        align-items: center;
      }

      // needed to increase specificity
      &-trigger.emu-button.emu-button-v2.emu-button {
        font: var(--emu-component-ingredients-text-text-typography-narrow);
        font-family: var(--emu-semantic-font-families-gotham-medium);
        font-size: inherit;
        letter-spacing: inherit;
        line-height: inherit;
        padding-top: 15px;
        padding-right: 20px;
        padding-bottom: 15px;
        margin-left: -33px;
        margin-right: -10px;
        cursor: default;

        &,
        &:hover,
        &:focus,
        &:active {
          background-color: var(--emu-common-colors-transparent);
          color: var(--emu-semantic-colors-tertiary-300);
          border: var(--emu-common-border-width-none);
          outline: none;
        }
      }

      &-flag-icon {
        padding-right: 10px;
      }
    }
  }

  &__main-navbar {
    &-container {
      position: relative;
      min-height: 50px; // as per live

      .image {
        display: inline-block;
      }
    }

    &-container-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // utilities to increase specificity
      &.header-main__x-centered {
        max-width: 1128px;
        margin-right: auto;
        margin-left: auto;
      }

      @include mq('large') {
        display: block;
      }
    }

    &-container-head-logo {
      a {
        display: inline-block;

        img {
          width: 125px; // as per live
          padding-top: 20px;
          padding-bottom: 20px;

          @include mq('large') {
            width: 154px; // as per live
          }
        }
      }
    }

    &-container-head-content {
      @include mq('large') {
        display: flex;
        flex-wrap: wrap;
        justify-content: end;
      }

      @include mq('1200px') {
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      >.image {
        padding-left: 20px;

        @include mq('large') {
          flex-basis: auto;
          width: 100%;
          padding-left: var(--emu-common-spacing-none);
          margin-left: 5px;
        }

        @include mq('1200px') {
          margin-left: var(--emu-common-spacing-none);
        }
      }

      >.text {
        @include mq('large') {
          flex-basis: auto;
        }
      }
    }

    &-container-head-menu {
      width: 100%;
      max-height: 65vh; // as per live
      border-top: 1px solid #e6e6e6; // used only in this file
      border-top-width: var(--emu-common-border-width-thin);
      border-top-style: solid;
      border-top-color: #e6e6e6; // used only in this file
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      overflow: hidden;
      z-index: var(--emu-common-other-z-index-layer);
      max-height: 0;
      transition: max-height var(--emu-common-other-time-duration-instant);

      &.active {
        max-height: 65vh;

        @include mq('large') {
          max-height: none;
        }
      }

      &.show-overflow {
        overflow: auto;
      }

      ul {
        max-height: 65vh;
        margin-top: var(--emu-common-spacing-none);
        margin-bottom: var(--emu-common-spacing-none);
        padding-left: var(--emu-common-spacing-none);

        li {
          font-family: var(--emu-semantic-font-families-gotham-medium);
          font-weight: var(--emu-semantic-font-weight-500);
          list-style: none;
          height: 100%;
          text-decoration: none;
          color: var(--emu-semantic-colors-secondary-400);

          .emphasis {
            a {
              font-size: 9.5px;
              line-height: 12px;
              color: var(--emu-semantic-colors-tertiary-300);

              @include mq('large') {
                display: none;
              }
            }
          }

          a {
            display: block;
            font-size: 11px;
            line-height: 13px;
            padding-top: 13px;
            padding-right: 12px;
            padding-bottom: 13px;
            padding-left: 12px;

            color: var(--emu-semantic-colors-secondary-400);
            text-decoration: none;

            @include mq('480px') {
              padding-top: 15px;
              padding-bottom: 15px;
            }

            @include mq('large') {
              line-height: 15px;
              padding-top: 30px;
              padding-right: 15px;
              padding-bottom: 30px;
              padding-left: 15px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      &--desktop {
        @include mq('large') {
          display: block;
          height: auto;
          position: unset;
          max-height: unset;
          border-top: none;

          ul {
            display: flex;
            padding-left: 12px;

            li {
              a {
                font-size: 13px;
                white-space: nowrap;
                line-height: 15px;
              }
            }
          }
        }
      }
    }

    &-menu-btn {
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;

      // utilities to increase specificity
      &.header__main-navbar-menu-large.emu-button.emu-button-v2 {
        padding-top: 29px;
        padding-right: 26px;
        padding-bottom: 29px;
        padding-left: 26px;
        border: none;

        &,
        &:hover,
        &:active,
        &:focus {
          background-color: var(--emu-common-colors-transparent);
        }

        @include mq('large') {
          display: none;
        }
      }

      span {
        background-color: var(--emu-semantic-colors-secondary-400);
        width: 15px;
        height: 2px;
        border-radius: 1px;

        &::before,
        &::after {
          content: '';
          width: 15px;
          height: 2px;
          background-color: var(--emu-semantic-colors-secondary-400);
          border-radius: 1px;
          position: absolute;
          left: 26px;
        }

        &::before {
          top: 29px;
        }

        &::after {
          bottom: 29px;
        }
      }
    }

    &-social-media {
      display: flex;
      justify-content: center;

      @include mq('large') {
        padding-bottom: 10px;
      }

      .image {
        padding-top: 20px;

        >.cmp-image {
          margin-right: 14px;

          &.mr-0 {
            margin-right: var(--emu-common-spacing-none);
          }
        }

        @include mq('large') {
          height: 100%;
          padding-top: var(--emu-common-spacing-none);
        }

        img {
          width: 25px;
          cursor: pointer;

          @include mq('large') {
            width: 36px;
          }
        }
      }
    }

    &-social-media-text {
      display: none;

      @include mq('large') {
        display: block;
      }

      h4 {
        font-family: var(--emu-semantic-font-families-gotham-medium);
        font-weight: var(--emu-semantic-font-weight-500);
        font-size: 13px;
        margin-right: 14px;

        @include mq('large') {
          line-height: 15px;
          margin-right: var(--emu-common-spacing-none);
          padding-top: 10px;
          padding-right: 15px;
          padding-bottom: 10px;
          padding-left: 15px;
        }
      }
    }
  }
}
.intro {
  &__title {
    p {
      display: inline;
      max-width: 680px; 
    }
    @include mq('large') {
      display: block;
      max-width: 800px; 
    }

    .cmp-title__text {
      padding-left: 6px; //to match design
      padding-right: 6px; //to match design
      margin-bottom: var(--emu-common-spacing-small);
      color: var(--emu-semantic-colors-secondary-200);
      font-weight: var(--emu-common-font-weight-regular);
      letter-spacing: -0.9px;

      @include mq('large') {
        font-size: var(--emu-semantic-font-sizes-wide-xxxl);
        line-height: var(--emu-semantic-line-heights-wide-xxxl);
        letter-spacing: -1.4px;
        margin-bottom: var(--emu-common-spacing-medium);
      }

      sup {
        font-size: 30px;
        top: 1px;

        @include mq('large') {
          font-size: 33px;
          top: -16px;
          left: 3px;
        }
      }
    }
  }

  &__description {
    color: var(--emu-semantic-colors-tertiary-dark);
    line-height: 22.4px;

    @include mq('large') {
      max-width: 680px; //as per design
      line-height: var(--emu-semantic-line-heights-wide-small);
    }

    sup {
      font-size: 23px;
      top: 4px; //as per design
    }
  }
}

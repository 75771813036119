// set visibility by device/screen size
.desktop-view {
  display: none;

  @include mq('large') {
    display: block;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// visible on tablet size & larger
.tablet-desktop-view {
  display: none;

  @include mq('medium') {
    display: block;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

// visible on tablet size & smaller
.tablet-mobile-view {
  display: block;

  @include mq('large') {
    display: none;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

.mobile-view {
  display: block;

  @include mq('medium') {
    display: none;
  }

  @include aem-editor-view {
    display: block !important;
  }
}

.x-centered-xx-large {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--emu-semantic-sizing-breakpoints-xx-large);
}

// padding adjustments
.no-pad {
  padding: var(--emu-common-spacing-none);
}

.no-x-pad {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.no-y-pad {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
}

// content related util classes.
.content--centered {
  text-align: center;
}

// background color related classes
.bg--white {
  background-color: var(--emu-common-colors-white);
}

.bg--black {
  background-color: var(--emu-common-colors-black);
}

.bg--primary-light {
  background-color: var(--emu-semantic-colors-primary-light);
}

.bg--primary-dark {
  background-color: var(--emu-semantic-colors-primary-dark);
}

.bg--primary-50 {
  background-color: var(--emu-semantic-colors-primary-50);
}

.bg--primary-100 {
  background-color: var(--emu-semantic-colors-primary-100);
}

.bg--primary-200 {
  background-color: var(--emu-semantic-colors-primary-200);
}

.bg--secondary-light {
  background-color: var(--emu-semantic-colors-secondary-light);
}

.bg--secondary-dark {
  background-color: var(--emu-semantic-colors-secondary-dark);
}

.bg--secondary-50 {
  background-color: var(--emu-semantic-colors-secondary-50);
}

.bg--secondary-100 {
  background-color: var(--emu-semantic-colors-secondary-100);
}

.bg--secondary-200 {
  background-color: var(--emu-semantic-colors-secondary-200);
}

.bg--secondary-300 {
  background-color: var(--emu-semantic-colors-secondary-300);
}

.bg--secondary-400 {
  background-color: var(--emu-semantic-colors-secondary-400);
}

.bg--tertiary-light {
  background-color: var(--emu-semantic-colors-tertiary-light);
}

.bg--tertiary-dark {
  background-color: var(--emu-semantic-colors-tertiary-dark);
}

.bg--tertiary-50 {
  background-color: var(--emu-semantic-colors-tertiary-50);
}

.bg--tertiary-100 {
  background-color: var(--emu-semantic-colors-tertiary-100);
}

.bg--tertiary-200 {
  background-color: var(--emu-semantic-colors-tertiary-200);
}

.bg--tertiary-300 {
  background-color: var(--emu-semantic-colors-tertiary-300);
}

// text color related classes
[data-component='title'],
[data-component='text'] {
  &.text--color-white {
    color: var(--emu-common-colors-white);
  }

  &.text--color-black {
    color: var(--emu-common-colors-black);
  }

  &.text--color-primary-light {
    color: var(--emu-semantic-colors-primary-light);
  }

  &.text--color-primary-dark {
    color: var(--emu-semantic-colors-primary-dark);
  }

  &.text--color-primary-50 {
    color: var(--emu-semantic-colors-primary-50);
  }

  &.text--color-primary-100 {
    color: var(--emu-semantic-colors-primary-100);
  }

  &.text--color-primary-200 {
    color: var(--emu-semantic-colors-primary-200);
  }

  &.text--color-secondary-light {
    color: var(--emu-semantic-colors-secondary-light);
  }

  &.text--color-secondary-dark {
    color: var(--emu-semantic-colors-secondary-dark);
  }

  &.text--color-secondary-50 {
    color: var(--emu-semantic-colors-secondary-50);
  }

  &.text--color-secondary-100 {
    color: var(--emu-semantic-colors-secondary-100);
  }

  &.text--color-secondary-200 {
    color: var(--emu-semantic-colors-secondary-200);
  }

  &.text--color-secondary-300 {
    color: var(--emu-semantic-colors-secondary-300);
  }

  &.text--color-secondary-400 {
    color: var(--emu-semantic-colors-secondary-400);
  }

  &.text--color-tertiary-light {
    color: var(--emu-semantic-colors-tertiary-light);
  }

  &.text--color-tertiary-dark {
    color: var(--emu-semantic-colors-tertiary-dark);
  }

  &.text--color-tertiary-50 {
    color: var(--emu-semantic-colors-tertiary-50);
  }

  &.text--color-tertiary-100 {
    color: var(--emu-semantic-colors-tertiary-100);
  }

  &.text--color-tertiary-200 {
    color: var(--emu-semantic-colors-tertiary-200);
  }

  &.text--color-tertiary-300 {
    color: var(--emu-semantic-colors-tertiary-300);
  }
}

.typography-body-xl {
  font-size: 22px;
  line-height: 30.8px;
}

.typography-body-l {
  font-size: 18px;
  line-height: 25.2px;
}

.typography-body {
  font-size: 16px;
  line-height: 22.4px;
}

.typography-body-s {
  font-size: 14px;
  line-height: 16.8px;
}

.typography-body-xs {
  font-size: 12px;
  line-height: 14.4px;
}

.typography-body-xxs {
  font-size: 10px;
  line-height: 12px;
}

.bg-linear-gradient-primary {
  background: linear-gradient(
    90deg,
    var(--emu-semantic-colors-primary-200) 0%,
    var(--emu-semantic-colors-primary-100) 100%
  );
}

.center-direct-children {
  padding-left: 12px;
  padding-right: 12px;

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
  }
}

.tab-content-container-like {
  background-color: var(--emu-common-colors-white);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
  box-shadow: 0px 4px 24px 0px #0000001a;

  @include mq('large') {
    padding-left: 104px;
    padding-right: 104px;
    padding-bottom: var(--emu-common-spacing-xl);
  }
}

.u-hide-even-controls-in-desktop {
  @include mq('large') {
    .tns-nav button:nth-child(2n) {
      display: none;
    }
  }
}

#patientfi {
  .patientfi-carousel {
    // Couldn't find the token that applies `padding-bottom`
    padding-bottom: var(--emu-common-spacing-none);

    .tns-controls {
      display: flex;
      justify-content: center;
      column-gap: 14px;
      // 22.64px +8px = 30.64px
      // 22.64px (from the design)
      // 8px     (The height of the dots)
      margin-top: 30.64px;

      @include mq('large') {
        // 35px +8px = 43px
        margin-top: 43px;
        margin-bottom: 38px;
      }

      button.emu-carousel__action {
        position: static;
        transform: none;
        min-height: 48px;
        min-width: 80px;
        background-repeat: no-repeat;
        background-position: center;

        &:hover {
          background-color: var(--emu-semantic-colors-primary-50);
        }

        &:active {
          background-color: var(--emu-semantic-colors-primary-50);
          filter: brightness(85%);
        }

        &.emu-carousel__action-prev {
          background-image: url(../../assets/images/arrow-left.png);
        }

        &.emu-carousel__action-next {
          background-image: url(../../assets/images/arrow-right.png);
        }
      }
    }

    &-container {
      &__title {
        margin-bottom: var(--emu-common-spacing-small);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-xs);
        }

        h4 {
          font-size: var(--emu-semantic-font-sizes-narrow-xxxl);
          line-height: var(--emu-semantic-line-heights-narrow-xxl);
          letter-spacing: -1.12px;
          // Actual value: 350
          font-weight: var(--emu-common-font-weight-regular);

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-xxl);
            line-height: var(--emu-semantic-line-heights-wide-xxl);
            letter-spacing: -1.6px;
          }
        }
      }

      &__text {
        margin-bottom: 25px;
        font-size: var(--emu-semantic-font-sizes-narrow-small);
        line-height: var(--emu-semantic-line-heights-narrow-small);

        @include mq('large') {
          max-width: 540px;
          margin-bottom: 28px;
          margin-left: auto;
          margin-right: auto;
          font-size: var(--emu-semantic-font-sizes-wide-small);
          line-height: var(--emu-semantic-line-heights-wide-small);
        }
      }

      &__content {
        // to make the content look good
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;

        @include mq('large') {
          max-width: none;
          margin-left: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }

      &__images {
        display: flex;
        position: relative;

        .container {
          position: relative;
          overflow: visible;
          flex-grow: 1;
          // Necessary to stop layout shift
          max-width: 50%;

          img {
            object-fit: cover;
            width: 100%;
            // Necessary to stop layout shift
            aspect-ratio: 159.5 / 151.688;

            @include mq('large') {
              aspect-ratio: 243 / 233;
            }
          }
        }

        >.image {
          position: absolute;
          top: 0;
          right: 0;
          z-index: var(--emu-common-other-z-index-layer);
          // from the design
          width: 52.08px;
          aspect-ratio: 1;

          @include mq('large') {
            // from the design
            width: 80px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &__image-text {
        p {
          font-family: var(--emu-semantic-font-families-gotham-bold);
          font-size: 10px;
          font-weight: var(--emu-semantic-font-weight-bold);
          letter-spacing: -0.4px;
          line-height: 12px;
          height: 29.95px;
          padding-top: 9.77px;
          padding-bottom: 8.18px;

          @include mq('large') {
            font-size: var(--emu-semantic-font-sizes-wide-small);
            line-height: var(--emu-semantic-line-heights-wide-small);
            letter-spacing: normal;
            height: 46px;
            padding-top: 6px;
            padding-bottom: 15px;
          }
        }
      }

      &__content-desc {
        margin-top: 9.37px;
        margin-bottom: 32.37px;
        font-size: var(--emu-common-font-sizes-narrow-medium);
        line-height: 14.4px;
        letter-spacing: -0.48px;

        @include mq('large') {
          margin-top: 19px;
          margin-bottom: 46px;
          font-size: var(--emu-common-font-sizes-wide-medium);
          line-height: 22.4px;
          letter-spacing: normal;
        }

        b {
          font-family: var(--emu-semantic-font-families-gotham-bold);
          font-weight: bold;
        }
      }
    }
  }
}